import React from "react";
import "./about.css";
import SocialContact from "../../shared/social/social";

function About() {
    return (
        <div className="about">
                <div className="about-info">
                    Hi!<br />
                    I'm Andrea Cavezzale, <b>MSc student in Computer Science and Engineering</b>.
                    <br />
                    This website contains a collection of my projects and my personal information.
                <SocialContact />
                </div>
                <div className={"about-image"}>
                    <img src="/assets/avatar.png" className="image" alt="avatar"></img>
                </div>
        </div>
    );
}

export default About;