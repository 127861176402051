import React from "react";
import './menu.css';
import { BsPersonSquare } from "react-icons/bs";
import { BsFillShareFill } from "react-icons/bs";
import { BsLightbulbFill } from "react-icons/bs";
import { BsFillClipboardCheckFill } from "react-icons/bs";
import ToggleDarkLight from "./toggleDarkLight"

function Menu() {
    function moveToProjects() {
            const element = document.getElementById('Projects');
            element.scrollIntoView({behavior: 'smooth'});
        }

    function moveToAbout() {
            const element = document.getElementById('About');
            element.scrollIntoView({behavior: 'smooth'});
    }

    function moveToInterests() {
        const element = document.getElementById('Interests');
        element.scrollIntoView({behavior: 'smooth'});
    }

    function moveToContact() {
        const element = document.getElementById('Contact');
        element.scrollIntoView({behavior: 'smooth'});
    }

    return (
        <div className="header">
            {/* <img src={"./assets/LogoWhite.png"} alt="Logo" className={"logo"}></img> */}
            <ToggleDarkLight />
            <div className="links">
                <div className="menu-option" onClick={moveToAbout}>
                    <p><i className="menu-icon"><BsPersonSquare /></i><i className="menu-text"> About</i></p>
                </div>
                <div className="menu-option" onClick={moveToProjects}>
                    <p><i className="menu-icon"><BsFillClipboardCheckFill /></i> <i className="menu-text"> Projects </i></p>
                </div>
                <div className="menu-option" onClick={moveToInterests}>
                    <p><i className="menu-icon"><BsLightbulbFill /></i><i className="menu-text"> Interests </i></p>
                </div>
                <div className="menu-option" onClick={moveToContact}>
                    <p><i className="menu-icon"><BsFillShareFill /></i><i className="menu-text"> Contact </i></p>
                </div>
            </div>
        </div>
    );
}

export default Menu;