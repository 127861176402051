export const SocialDataDarkIcons=[
    {
        platform:"Github",
        icon: ("/assets/github.png"),
        link:"https://github.com/Cave-8",
    },
    {
        platform:"Linkedin",
        icon: ("/assets/linkedin.png"),
        link:"https://www.linkedin.com/in/andrea-cavezzale",
    },
];