import React from "react";
import "./header.css";
import Menu from "./menu/menu"

function Header() {
    return (
        <div className="header">

            <div className="menu">
                    <Menu />
            </div>
        </div>
    );
}

export default Header;