import React from "react";
import {SocialDataDarkIcons} from "../../data/social";
import "./social.css";

function SocialContact() {

    const downloadCV = () => {
        fetch('/assets/CV.pdf').then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let downloadLink = document.createElement("a");
                downloadLink.href = fileURL;
                downloadLink.download = 'Andrea_Cavezzale_CV.pdf';
                downloadLink.click();
            })
        })
    }

    return (
        <div className="social-contact" class="social-contact">
            {SocialDataDarkIcons.map ((item) => {
                return (
                    <a href={item.link}>
                        <div className="social-icon-div">
                            <img src={item.icon} className="social-icon" alt={"Icon"}/>
                        </div>
                    </a>
                );
            })}
            {/*
            /*<div>
                <img src="/assets/CV.png" alt="CV" className="cv social-icon" onClick={downloadCV}></img>
            </div>
            */}
        </div>
    );
}

export default SocialContact;