export const ProjectData=[
    {
        title:"Enigma machine",
        about: "A fully customizable Rust implementation of an Enigma machine to both encrypt and decrypt text.",
        languages:["Rust"],
        github:"https://github.com/Cave-8/Enigma-machine",
    },
    {
        title:"Expenses manager",
        about: "A small utility to manage your expenses, made using Python (with a TUI) and MongoDB.",
        languages:["Python"],
        github:"https://github.com/Cave-8/ExpensesManager",
    },
    {
        title:"Advent of code",
        about: "I like to solve Advent Of Code challenges, for 2023 I chose Rust and a pinch of Python, for 2022 I chose Haskell.",
        github: "https://github.com/Cave-8/Advent-Of-Code",
        languages:["Rust", "Python", "Haskell"],
    },
    {
        title:"MyShelfie - Academic project",
        about: "Multiplayer client-server board-game implemented with Java and JavaFX for GUI.",
        languages:["Java", "JavaFX"],
        github: "https://github.com/DanCiob/IS23-AM17",
    },
    {
        title:"Playlist manager - Academic project",
        about: "Playlist manager developed in two versions, pureHTML with Thymeleaf and Javascript for frontend and Java for backend, sharing a common database.",
        languages:["Java", "Thymeleaf", "Javascript", "SQL"],
        github:"https://github.com/Cave-8/Web-technology-project",
    },
    {
        title:"Algorithm and data structure project - Academic project",
        about: "Implementation of a wordle-like game with particular attention to both time and space complexity.",
        languages:["C"],
        github:"https://github.com/Cave-8/Algorithms-and-Data-Structure-Project"
    },
    {
        title:"A Deterministic Pushdown Automaton simulator (DPDA)",
        about: "A configurable Deterministic Pushdown Automaton to recognize if a set of string belongs to a language recognizable by DPDA.",
        languages:["C"],
        github:"https://github.com/Cave-8/Pushdown-Automaton"
    },
    {
        title:"Red Black tree library",
        about: "Library containing functions to operate with red black trees (creation, node addition/deletion).",
        languages:["C"],
        github:"https://github.com/Cave-8/Red-black-tree-lib"
    },
    {
        title:"String library",
        about: "Small collection of utilities for string analysis like: global and local sequence alignment, common substring and subsequence finder and Burrows-Wheeler encoder and decoder.",
        languages:["Python"],
        github:"https://github.com/Cave-8/String-Utilities",
    },
    {
        title:"Graph library",
        about: "Library containing functions for graph analysis (Eulerian path and shortest path finder) and editing (with JSON compatibility).",
        languages:["Kotlin"],
        github:"https://github.com/Cave-8/Graph-library",
    },
    {
        title:"My Website",
        about: "One page website showing my projects and contact information, developed using React.",
        languages:["Javascript", "React"],
    },
];