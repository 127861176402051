import * as React from 'react';
import { DarkModeSwitch } from 'react-toggle-dark-mode';

const ToggleDarkLight = () => {
    const [isDarkMode, setDarkMode] = React.useState(false);

    const toggleDarkMode = (checked: boolean) => {
        setDarkMode(checked)
        if (!checked) {
            document.getElementById("home").style.backgroundColor = "#ffffff";
            document.getElementById("home").style.color = "#000000"

            const separators = document.getElementsByClassName("separator");
            for (let i = 0; i < separators.length; i++) {
                separators[i].style.backgroundColor = "#000000"
            }

            const githubLinks = document.getElementsByClassName("project-link-github");
            for (let i = 0; i < githubLinks.length; i++) {
                githubLinks[i].src = "/assets/github.png";
            }

            const socialIcons = document.getElementsByClassName("social-icon");
            for (let i = 0; i < socialIcons.length; i++) {
                let src = socialIcons[i].src.split('assets')
                // eslint-disable-next-line default-case
                switch ("/assets" + src[1]) {
                    case '/assets/githubWhite.png':
                        socialIcons[i].src = "/assets/github.png";
                        break;
                    case '/assets/linkedinWhite.png':
                        socialIcons[i].src = "/assets/linkedin.png";
                        break;
                    case '/assets/CVWhite.png':
                        socialIcons[i].src = "/assets/CV.png";
                        break;
                }
            }
        }
        else {
            document.getElementById("home").style.backgroundColor = "#222222F0";
            document.getElementById("home").style.color = "#ffffff"

            const separators = document.getElementsByClassName("separator");
            for (let i = 0; i < separators.length; i++) {
                separators[i].style.backgroundColor = "#ffffff"
            }

            const githubLinks = document.getElementsByClassName("project-link-github");
            for (let i = 0; i < githubLinks.length; i++) {
                githubLinks[i].src = "/assets/githubWhite.png"
            }

            const socialIcons = document.getElementsByClassName("social-icon");
            for (let i = 0; i < socialIcons.length; i++) {
                let src = socialIcons[i].src.split('assets')
                // eslint-disable-next-line default-case
                switch ("/assets" + src[1]) {
                    case '/assets/github.png':
                        socialIcons[i].src = "/assets/githubWhite.png";
                        break;
                    case '/assets/linkedin.png':
                        socialIcons[i].src = "/assets/linkedinWhite.png";
                        break;
                    case '/assets/CV.png':
                        socialIcons[i].src = "/assets/CVWhite.png";
                        break;
                }
            }
        }
    };

    return (
        <DarkModeSwitch
            checked={isDarkMode}
            onChange={toggleDarkMode}
            size={64}
            style = {{marginLeft: "32px"}}
        />
    );
};

export default ToggleDarkLight;