import React from "react";
import "./projectTab.css";
function ProjectTab({ project }) {
    return (
        <div className="project-card">
            <div className="project-info">
                <label className="project-title">{project.title}</label>
                <p>{project.about}</p>
                <div className="project-links">
                    {project.github&& (
                        <a className="project-link-github" href={project.github}>
                            <img src={"/assets/github.png"} alt="Link to repository" className={"project-link-github"}></img>
                        </a>
                    )}
                    {project.languages.map((tag) => {
                        return <img src={"/assets/"+tag.toLowerCase()+".png"} alt={tag} className={"project-link"}></img>
                    })}
                </div>
            </div>
        </div>
    );
}

export default ProjectTab;